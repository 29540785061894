import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import Main from '~components/layouts/Main'
import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'
import HeroSection from '~components/layouts/marketing/HeroSection'
import SEO from '~components/seo'

const StyledContainer = styled(Container)`
  max-width: 1280px !important;
`

const Content = styled.div`
  h2 {
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: -0.0125em;
    font-weight: 600;
    color: ${scssVars.darkBrown};
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  ul {
    padding-left: 20px !important;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: ${scssVars.darkBrown};
  }
  li {
    font-size: 16px;
    font-weight: 400;
    color: ${scssVars.darkBrown};
  }
  hr {
    border-color: ${scssVars.darkBrown};
    margin: 25px 0;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    h2 {
      font-size: 14px;
    }
    h3 {
      font-size: 22px;
    }
    li {
      line-height: 2;
    }
  }
`

const GoldBox = styled.div`
  display: inline-block;
  padding: 20px 25px;
  border: 2px solid ${scssVars.orange};
  h4 {
    font-size: 20px;
    font-weight: 600;
  }
  ul {
    padding-left: 15px;
  }
  li {
    font-size: 16px;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    padding: 50px 70px;
    li {
      line-height: 2;
    }
  }
`

const InquirySection = styled.section`
  h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    a {
      color: ${scssVars.darkBrown};
    }
  }
`

const CtaLink = styled(Link)`
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25) !important;
  max-width: 300px !important;
  font-size: 14px !important;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: inline-block;
    font-size: 16px !important;
  }
`

const CustomerServicesPage = () => (
  <Layout>
    <SEO
      title="Corporate"
      pathname="/corporate"
      description="Leather Spa provides high-quality repair and care services for luxury businesses"
    />
    <Main>
      <HeroSection
        breadcrumb={{ title: 'Customer Services', path: '/customer-services' }}
        title={'Corporate'}
        mobileImg={'https://leatherspa.imgix.net/IMG_7687@2x.png?fit=scale&w=992&fm=jpg'}
        desktopImg={'https://leatherspa.imgix.net/IMG_7687@2x.png?fit=scale&w=1360&fm=jpg'}>
        <>
          <h2>LEATHER SPA PROVIDES HIGH-QUALITY REPAIR AND CARE SERVICES FOR LUXURY BUSINESSES.</h2>
          <p>For decades, Leather Spa has been the trusted, go-to accessory repair service provider for the world’s leading luxury, fashion and accessory companies for over 30 years. We pride ourselves in providing unparalleled service and the highest quality repair techniques for over 300 corporate account partners.</p>
        </>
      </HeroSection>
      <section className="py-0 pt-md-3 py-lg-5">
        <StyledContainer>
          <Row>
            <Col md={{ size: 6 }} >
              <Content className="pr-4">
                <h3>Benefits of a Leather Spa corporate account include:</h3>
                <ul>
                  <li>Secure account registry on LeatherSpa.com providing ability to login and check status of orders, view account/repair history, and more</li>
                  <li>Dedicated corporate account manager to ensure quality service</li>
                  <li>Highest quality materials, often from the same suppliers as our partners</li>
                  <li>Convenient, monthly billing system</li>
                  <li>Repair pick-up and delivery (Manhattan Only)</li>
                  <li>Knowledge of industry trends, fabrics, materials, and more</li>
                  <li>Proven history servicing the world’s most discerning clients</li>
                </ul>
              </Content>
            </Col>
            <Col md={{ size: 6 }} className="d-flex justify-content-center">
              <GoldBox>
                <div className="align-self-center">
                  <h4>Businesses We Serve</h4>
                  <ul className="mb-0">
                    <li>Shoe Companies</li>
                    <li>Handbag Companies</li>
                    <li>Fashion and Accessories Companies</li>
                    <li>Runway Shows and Fashion Events</li>
                    <li>Luxury Department Stores</li>
                    <li>Luxury Online Retailers</li>
                    <li>Luxury Hotels</li>
                  </ul>
                </div>
              </GoldBox>
            </Col>
          </Row>
        </StyledContainer>
      </section>
      <InquirySection className="bg-clr-cararra mt-5 py-5 text-center">
        <Container>
          <h5>For Any Corporate Account Inquiries, Please Contact Us:</h5>
          <p>
            Tel: <a href="tel:+17183929220;4">718.392.9220 Ext. 4</a>
            <br />
            Email: <a href="mailto:office@leatherspa.com">office@leatherspa.com</a>
          </p>
          {/* <div className="mt-4 d-flex justify-content-between">
            <Col className="d-none d-lg-block" />
            <CtaLink className="mr-1 btn btn-outline-primary">
              Corporate Login
            </CtaLink>
            <CtaLink className="ml-1 btn btn-primary">
              Request Access
            </CtaLink>
            <Col className="d-none d-lg-block" />
          </div> */}
        </Container>
      </InquirySection>
    </Main>
  </Layout>
)

export default CustomerServicesPage
